import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Future`}</h1>
    <h2>{`Education`}</h2>
    <p>{`After recently completing a capstone research project in the brain-computer interface (BCI) field I discovered a passion for applications development through novel utilisation of emerging hardware. To pursue this interest and hone my technical and transferable skills in this area I am undertaking a PhD at UTS. In this degree, I hope to further my understanding of the BCI field and improve performance BCI applications, particularly regarding mobile and AR devices.`}</p>
    <p>{`Ultimately, I hope to leverage these academic skills to work towards my goal of producing digital systems that provide a genuine benefit to society.`}</p>
    <h2>{`Professional Development`}</h2>
    <p>{`While furthering my academic career is a priority, so too is my development as a practising engineer. To facilitate this I intend to continue my work in mobile applications development. As this sector rapidly evolves there are constantly new technologies emerging and therefore new development opportunities. By observing these changes I have built a list of technologies I would like to become familiar with:`}</p>
    <h3>{`Flutter`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Flutter SDK is Google's UI toolkit for crafting beautiful, natively compiled applications for mobile, web, and desktop from a single codebase.`}</p>
    </blockquote>
    <p>{`This technology has gained significant traction in recent years and is often appearing as a requirement in mobile developer roles. Alongside other cross-platform tools such as React Native, this framework attempts to simplify the development process by serving multiple app variants from a single codebase.`}</p>
    <p>{`While technologies such as these are often ephemeral, familiarising with a new language (in this case Dart) or technology always provides a broader understanding of the software landscape which is a valuable tool when developing in any context.`}</p>
    <h3>{`Kotlin Multiplatform Mobile`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Kotlin Multiplatform Mobile (KMM) is an SDK that allows you to use the same business logic code in both iOS and Android applications.`}</p>
    </blockquote>
    <p>{`In a similar approach to Flutter, KMM attempts to facilitate the development of iOS and Android apps with a single codebase. While still in the early stages of development, Kotlin's other transpilation frameworks such as Kotlin JS or Kotlin Native have proved significantly useful.`}</p>
    <p>{`While I am familiar with Kotlin (and Kotlin JS) through Android and web development I intend to utilise this tool in developing native applications with a common codebase.`}</p>
    <h3>{`Android's Jetpack Tools`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Jetpack is a suite of libraries to help developers follow best practices, reduce boilerplate code, and write code that works consistently across Android versions`}</p>
    </blockquote>
    <p>{`As the recommended processes and tools in Android development rapidly change it becomes useful to familiarise with Android's Jetpack suite that outlines several best practices and libraries to facilitate their implementation. I am already using several these tools in production applications such as Navigation and Room but would like to become more familiar with new tools.`}</p>
    <p>{`One such example of these newer tools in Jetpack Compose which provides a new application design framework by utilising a similar component-based top-down data model as React-Native. I believe I could leverage my existing React-Native skills to quickly learn this tool.`}</p>
    <h3>{`Mobile Web Development`}</h3>
    <p>{`While native mobile applications are still hugely popular, over `}<a parentName="p" {...{
        "href": "https://au.oberlo.com/statistics/mobile-internet-traffic"
      }}>{`40% of web is accessed through mobile devices`}</a>{`. As technologies in web development devolve towards more a more native application feel, I believe we may see a decline in native mobile applications as we have moved away from native desktop application in favour of web apps.`}</p>
    <p>{`Taking this into consideration I am making it a priority to ensure I am familiar with current web development technologies, particularly those that facilitate mobile interfaces.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      